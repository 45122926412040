<template>
  <div class="company-contain">
    <div class="head_box">
      <div class="filter-box">
        <!-- 日周月榜 -->
        <div class="timeBox" style="padding-left: 20px">
          <el-radio-group
            class="radio-group"
            v-model="radio"
            size="small"
            @change="getListByTime"
          >
            <el-radio-button label="1">日榜</el-radio-button>
            <el-radio-button label="2">周榜</el-radio-button>
            <el-radio-button label="3">月榜</el-radio-button>
          </el-radio-group>
        </div>
        <!-- 时间搜索 -->
        <div class="dataBox" style="margin-left: 20px">
          <el-date-picker
            v-if="this.radio == 1"
            v-model="datetime"
            type="date"
            :picker-options="pickerOptions"
            placeholder="选择日期"
            value-format="yyyyMMdd"
            @change="chooseDate"
          >
          </el-date-picker>
          <el-date-picker
            ref="menuWeek"
            id="getWeek"
            v-if="this.radio == 2"
            v-model="datetime"
            type="week"
            :picker-options="pickerDisabled"
            format="yyyy 第 WW 周"
            placeholder="选择日期"
            value-format="yyyyMMdd"
            @change="selectWeekDate"
          >
          </el-date-picker>

          <el-date-picker
            v-if="this.radio == 3"
            v-model="datetime"
            type="month"
            placeholder="选择月"
            value-format="yyyyMM"
            @change="selectMonthDate"
          >
          </el-date-picker>

        </div>
        <el-input clearable @change="getAllClass('seach')"  prefix-icon="el-icon-search" class="el-int"  placeholder="请输入博主名称" v-model="keyword"></el-input>
        <!-- 教学指导 -->
        <div class="teachingGuidance" style="position: absolute; right: 2%">
          <el-popover
            class="tightCount"
            placement="bottom"
            width="300"
            trigger="click"
          >
            <div class="rightBox">
              <div
                class="head_title"
                style="text-align: center; font-size: 16px; line-height: 40px"
              >
                帮助中心
              </div>
              <div class="listBox">
                <div
                  class="box1"
                  style="display: flex; margin-top: 10px; position: relative"
                >
                  <div
                    class="num"
                    style="
                      width: 14px;
                      height: 14px;
                      background: #00f;
                      border-radius: 7px;
                      color: #fff;
                      text-align: center;
                      line-height: 14px;
                      font-size: 12px;
                      position: absolute;
                      top: 2px;
                      left: 0;
                    "
                  >
                    1
                  </div>
                  <div class="title" style="margin-left: 20px; font-size: 14px">
                    复盘网易、雪球等测试小游戏刷屏的背后？
                  </div>
                </div>
                <div
                  class="box1"
                  style="display: flex; margin-top: 10px; position: relative"
                >
                  <div
                    class="num"
                    style="
                      width: 14px;
                      height: 14px;
                      background: #00f;
                      border-radius: 7px;
                      color: #fff;
                      text-align: center;
                      line-height: 14px;
                      font-size: 12px;
                      position: absolute;
                      top: 2px;
                      left: 0;
                    "
                  >
                    2
                  </div>
                  <div class="title" style="margin-left: 20px; font-size: 14px">
                    线下课程 | 想要成为一个优秀的运营负责人，
                    到底该怎么高效做规划、带团队？线下课程 |
                    想要成为一个优秀的运营负责人，
                    到底该怎么高效做规划、带团队？
                  </div>
                </div>
                <div
                  class="box1"
                  style="display: flex; margin-top: 10px; position: relative"
                >
                  <div
                    class="num"
                    style="
                      width: 14px;
                      height: 14px;
                      background: #00f;
                      border-radius: 7px;
                      color: #fff;
                      text-align: center;
                      line-height: 14px;
                      font-size: 12px;
                      position: absolute;
                      top: 2px;
                      left: 0;
                    "
                  >
                    3
                  </div>
                  <div class="title" style="margin-left: 20px; font-size: 14px">
                    超详细的搜索攻略：彻底讲透搜索技巧超详细的搜索攻略：彻底讲透搜索技巧超详细的搜索攻略：彻底讲透搜索技巧超详细的搜索攻略：超详细的搜索攻略：彻底讲透搜索技巧超详细的搜索攻略：彻底讲透搜索技巧彻底讲透搜索技巧超详细的搜索攻略：彻底讲透搜索技巧
                  </div>
                </div>
                <div
                  class="box1"
                  style="display: flex; margin-top: 10px; position: relative"
                >
                  <div
                    class="num"
                    style="
                      width: 14px;
                      height: 14px;
                      background: #00f;
                      border-radius: 7px;
                      color: #fff;
                      text-align: center;
                      line-height: 14px;
                      font-size: 12px;
                      position: absolute;
                      top: 2px;
                      left: 0;
                    "
                  >
                    4
                  </div>
                  <div class="title" style="margin-left: 20px; font-size: 14px">
                    线下课程 | 想要成为一个优秀的运营负责人，
                    到底该怎么高效做规划、带团队？ 拷贝线下课程 |
                    想要成为一个优秀的运营负责人，
                    到底该怎么高效做规划、带团队？ 拷贝线下课程 |
                    想要成为一个优秀的运营负责人，
                    到底该怎么高效做规划、带团队？ 拷贝
                  </div>
                </div>
              </div>
            </div>
            <el-button slot="reference" type="primary">教学指导</el-button>
          </el-popover>
        </div>
      </div>
        <div class="saixuan-content">
            <div class="saixuan-ele">
                <span>达人地域</span><el-cascader v-model="areaValue" :options="provinceArr" @change="handleChange"></el-cascader>
            </div>
            <div class="saixuan-ele">
                <el-checkbox v-model="isCommerce" @change="checkboxEvt">带货达人</el-checkbox>
            </div>
        </div>
      <!-- 下划线 -->
      <div class="divide">
        <el-divider></el-divider>
      </div>
      <!--筛选条件  分类-->
      <div class="filter-box">
        <div class="box1">
          <span>任务分类：</span>
          <div class="filter-box1">
            <span @click="allClas" :class="{ activeClass: isAll == 0 }"
              >全部</span
            >
            <span
              @click="isAllS(item)"
              :class="{ activeClass: isAll == item.id }"
              v-for="item in typeLsit"
              :key="item.id"
              >{{ item.name }}</span
            >
          </div>
        </div>
      </div>
      <!-- 高级筛选 -->



    </div>
    <!-- 下划线 -->
    <div style="background-color: #f3f3f6; height: 20px; width: 100%"></div>
    <!-- 表单 -->
    <div class="table-box">
      <el-table
        :data="taskList"
        class="college-table"
        style="width: 100%; flex: 1"
        size="medium"
        :header-cell-style="{ fontWeight: 'normal', color: '#333' }"
        :cell-style="{ fontSize: '12px', color: '#333' }"
      >
        <el-table-column
          prop="rank"
          label="排行"
          align="left"
          width="100"
          class-name="student-name"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.rank }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="nickname"
          width="400"
          label="播主"
          align="left "
          class-name="student-name"
        >
          <template slot-scope="scope">
            <div class="showing">
              <img class="imgBox" :src="scope.row.avatar_medium_url" />
              <span class="showing-name">{{ scope.row.nickname }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="follower_count"
          label="点赞总数"
          align="center"
          class-name="student-name"
        >
          <template slot-scope="scope">
            <span class="student-name-title">{{
              scope.row.follower_count*50
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="follower_count"
          label="视频总数"
          align="center"
          class-name="student-name"
        >
          <template slot-scope="scope">
            <span class="student-name-title">{{
              Math.floor(Math.random() * (101)) + 100
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="follower_count"
          label="热度指数"
          align="center"
          class-name="student-name"
        >
          <template slot-scope="scope">
            <span class="student-name-title">{{
             scope.row.follower_goup_count*0.01.toFixed(2)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="follower_goup_count"
          width="100"
          :label="label"
          align="center"
          class-name="student-name"
        >
          <template slot="header" slot-scope="scope">
            <span style="color: #ff0000">{{ label }}</span>
          </template>
          <template slot-scope="scope">
            <div
              style="
                height: 32px;
                background: #ffa1a8;
                border: 1px solid rgba(255, 161, 168, 1);
                border-radius: 2px;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <span class="student-name-title" style="color: #ff0000"
                >+{{ scope.row.follower_goup_count }}</span
              >
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="follower_count"
          label="粉丝总数"
          align="center"
          class-name="student-name"
        >
          <template slot-scope="scope">
            <span class="student-name-title">{{
              scope.row.follower_count
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="total_favorited"
          label="粉丝增量"
          align="center"
          class-name="student-name"
        >
          <template slot-scope="scope">
            <span class="student-name-title">{{
              scope.row.follower_goup_count
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="aweme_count"
          label="商品数"
          align="center"
          class-name="student-name"
        >
          <template slot-scope="scope">
            <span class="student-name-title">{{
              scope.row.product_count
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250" align="center">
          <template slot-scope="scope">
            <div class="operation" @click="changeToDetail(scope.row)">
              <el-button type="primary" size="small">查看详情</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页按钮 -->
    <el-pagination
      class="pages-center"
      :current-page.sync="adminPages.currentPageNum"
      :page-size="adminPages.eachPageNum"
      layout="prev, pager, next, jumper"
      :total="adminPages.total"
      @current-change="adminCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
import _ from "lodash";
import dayjs from 'dayjs'
import { shwoRanking, shwoCategory } from "@/utils/apis";
import {provinces} from "@/utils/province_city.json";
import duration from "dayjs/plugin/duration";
import {endDate, defaultDay} from "@/utils/common";

dayjs.extend(duration)

export default {
  name: "HotVideo",
  data() {
    let _minTime = null;
    let _maxTime = null;
    return {
      keyword:'',
     pickerDisabled: {
        //禁选当天之后的日期
        disabledDate: (time) => {
          return time.getTime() > new Date() * 1 + 600 * 1000;
        },
      },
      pickerOptions: {
        onPick(time) {
          if (!time.maxDate) {
            let timeRange = 7 * 24 * 3600 * 1000;
            _minTime = time.minDate.getTime(); //最小时间
            _maxTime = time.minDate.getTime() + timeRange; //最大时间
          } else {
            _minTime = _maxTime = null;
          }
        },
        disabledDate(time) {
            return  time.getTime() > endDate();
          let afterToday = Date.now() - 3600 * 1000 * 24;
          if (_maxTime) {
            _maxTime = _maxTime <= afterToday ? _maxTime : afterToday;
          } else {
            return time.getTime() > Date.now() - 3600 * 1000 * 24;
          }
          if (_minTime && _maxTime) {
            return time.getTime() < _minTime || time.getTime() > _maxTime;
          }
        },
      },
      // monthPickerOptions: {
      //   //月榜时间选择器的约束
      //   disabledDate(time) {
      //     return time.getMonth() + 1 != new Date().getMonth() + 1;
      //   },
      // },
      fansValue: '',
      fansOptions:'',
      isAll: 0,
      radio: 1,
      type: 1, //类型日周月
      datetime: null, //获取选择的日周月数据
      is_date: 0, //排名信息
      nickname: "", //搜索框搜索内容
      cate: "", //分类名称
      typeLsit: [], //分类列表
      task_condition: "",
      taskList: [], //任务列表
      //分页
      adminPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      label: "单日涨粉",
      pointsValue: "", //带货口碑筛选
        provinceArr:[{label: '全部', value: '全部'}],
        areaValue: ['全部'],
        isCommerce: false
    };
  },
  mounted() {
    this.chooseDate();
    this.getTaskType();
    //格式化省份城市
    //   console.log('province',provinces.province);
      let cities = ['北京', '天津', '重庆', '上海'];
      let all_value = {label: '全部', value: '全部'};
      provinces.province.forEach((province, index) => {
          let provin = {
              label: province.ssqname,
              value: province.ssqname,
              children: cities.indexOf(province.ssqname) === -1 ? [all_value] : [all_value, {label: province.ssqname, value: province.ssqname}]
          }
          if (province.cities.city.length > 0 && ['台湾省', '香港', '澳门', '海外'].concat(cities).indexOf(provin.label) === -1) {
              province.cities.city.map((city, key) => {
                  provin.children.push({label: city.ssqname, value: city.ssqname});
              });
          }
          this.provinceArr.push(provin);
      });
  },
  methods: {
      checkboxEvt() {
            this.getAllClass();
      },
    isAllS(item) {
      this.isAll = item.id;
      this.cate = item.name;
    },
    //默认选中“全部”分类
    allClas() {
      this.isAll = 0;
      this.cate = "";
    },
    // 显示任务分类
    getTaskType() {
      let params = {};
      shwoCategory(params).then((res) => {
        this.typeLsit = res.data.list;
      });
    },
    getListByTime() {
      this.datetime = null; //清空之前选中的时间数据
      if (this.radio == 1) {
        this.type = 1;
        this.datetime = dayjs().subtract(1, 'day').format('YYYYMMDD');
      }
      if (this.radio == 2) {
        this.type = 2;
        let begin_week_datetime = dayjs().startOf('week').subtract(6, 'day').format('YYYYMMDD');
        this.datetime = begin_week_datetime;
      }
      if (this.radio == 3) {
        this.type = 3;
        this.datetime = dayjs().subtract(1, 'month').format('YYYYMM')
      }
      this.label =
        this.radio == 1
          ? "单日涨粉"
          : this.radio == 2
          ? "单周涨粉"
          : this.radio == 3
          ? "单月涨粉"
          : "单日涨粉";
      // this.getAllClass();
    },
    // 选择日
    chooseDate(val) {
      this.datetime = val;
    },
    // 选择周
    selectWeekDate(val) {
      console.log(val)
      this.datetime = val;
    },
    //选择月份
    selectMonthDate(val) {
      this.datetime = val;
    },
    // 时间戳为13位
    dateFormatTwo: function (time) {
      var date = new Date(time);
      var year = date.getFullYear();
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
      handleChange(e) {
        this.getAllClass();
      },
    // 获取所有涨粉榜数据
    getAllClass(type) {
      if(type=='seach'){
        this.adminPages.currentPageNum = 1;
      }
      let param = {
          page: this.adminPages.currentPageNum,
          limit: this.adminPages.eachPageNum,
          keyword:this.keyword
      };
      if (this.type) {
          param.type = this.type;
      }
      if (this.cate) {
          param.star_category = this.cate;
      }
      if (this.datetime) {
          switch (Number(this.type)) {
              case 1:
                  let time_str = dayjs(this.datetime).format('YYYY-MM-DD');
                  param.date = time_str;
                  break;
              case 2:
                  let end_datetime = dayjs(this.datetime).add(6, 'day').format('YYYYMMDD').toString();
                  param.date = `${this.datetime}-${end_datetime}`;
                  break;
              case 3:
                  param.date = this.datetime;
                  break;
          }
      } else {
          param.date = defaultDay();
      }
      if (this.areaValue[0] !== '全部') {
          param.province = this.areaValue[0];
          param.city = this.areaValue[1] === '全部' ? '' : this.areaValue[1];
      }
      if (this.isCommerce) {
          param.is_commerce = 1;
      }
      shwoRanking(param)
        .then((res) => {
          this.taskList = res.data.list;
          this.adminPages.total = res.data.total;

        })
        .catch((err) => {
          console.log(err);
        });
    },
    //分页
    adminCurrentChange(val) {
      this.adminPages.currentPageNum = val;
      this.getAllClass();
    },
    //跳到详情页
    changeToDetail(row) {
          localStorage.setItem('rank', row.rank);
      this.$router.push({
        path: "/student/hot/authorDetail",
        query: {
          author_id: row.userid,
          is_date: this.datetime!=undefined ? 1 : 0,
          date: this.datetime,
          type: this.type,
        },
      });
    },

    //防抖
    sendAjax: _.debounce(function () {
      this.getAllClass();
    }, 1000),
  },
  watch: {
    isAll(d) {
      this.getAllClass();
    },
    nickname(d) {
      this.sendAjax();
    },
    datetime(d) {
      this.sendAjax();
    },
    fansValue(d) {
      this.sendAjax();
    },
    pointsValue(d) {
      this.sendAjax();
    },
  },
};
</script>

<style scoped lang="scss">
.el-int{
      width: 250px;
      margin-left: 20px;
    }
.saixuan-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;

    .saixuan-ele {
        padding-left: 20px;
        & span {
            margin-right: 10px;
        }

    }
}
.activeClass {
  background: #2338e6;
  color: #ffffff;
}

.company-contain {
  margin-bottom: 20px;
  background-color: #ffffff;

  .timeBox {
    .radio-group {
      ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background-color: #2338e6;
        border-color: #2338e6;
      }

      ::v-deep
        .el-radio-button__orig-radio:checked
        + .el-radio-button__inner:hover {
        color: #fff;
      }

      ::v-deep .el-radio-button__inner:hover {
        color: #2338e6;
      }
    }
  }

  .teachingGuidance {
    ::v-deep .el-button--primary {
      color: #fff;
      background-color: #495df1;
      border-color: #495df1;
    }

    ::v-deep .el-button--primary:focus,
    .el-button--primary:hover {
      background: #495df1;
      border-color: #495df1;
      color: #fff;
    }

    ::v-deep .el-button {
      padding: 8px 13px;
      border-radius: 2px;
    }
  }

  .operation {
    ::v-deep .el-button--primary {
      color: #fff;
      background-color: #495df1;
      border-color: #495df1;
    }

    ::v-deep .el-button--primary:focus,
    .el-button--primary:hover {
      background: #495df1;
      border-color: #495df1;
      color: #fff;
    }

    ::v-deep .el-button {
      padding: 8px 13px;
      border-radius: 2px;
    }
  }

  .divide {
    padding: 0 20px;
  }

  .filter-box {
    display: flex;
    position: relative;

    .box1 {
      padding: 10px 20px;
      display: flex;
      line-height: 40px;

      .filter-box1 {
        flex: 1;

        span {
          margin-top: 6px;
          line-height: 32px;
          display: inline-block;
          width: 66px;
          height: 32px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }

  ::v-deep .el-table td {
    border-bottom: 8px solid #ebeef5;
  }

  ::v-deep .el-table {
    display: flex;
    flex-direction: column;
    height: calc(100% - 114px);
  }

  ::v-deep .el-table--border::after,
  ::v-deep .el-table--group::after,
  ::v-deep .el-table::before {
    background-color: transparent;
  }

  ::v-deep .el-table--medium td,
  ::v-deep .el-table--medium th {
    padding: 10px 9px;
  }

  ::v-deep .el-table__footer-wrapper,
  ::v-deep .el-table__header-wrapper {
    overflow: initial;
  }

  ::v-deep .el-table__body-wrapper {
    overflow: auto;
  }

  .showing {
    display: flex;
    align-items: center;
    /*width: 30px;*/
    height: 30px;

    img {
      display: inline-block;
      height: 30px;
      width: 30px;
      margin-right: 10px;
      border-radius: 50%;
    }

    .showing-name {
      max-width: 260px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
